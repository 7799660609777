.bb-loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;

  svg {
    width: 30rem;
    height: 30rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 464px) {
      width: 50rem;
      height: 50rem;
    }

    .bb-logo {
      animation: fadeInLogo 1s linear forwards,
        moveLogo 0.75s 1s cubic-bezier(0.51, 0.92, 0.54, 1) forwards;
      transform: translateX(30%);

      .white {
        fill: #fff;
      }

      .blue {
        fill: #2cbacb;
      }
    }

    .path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      opacity: 0;

      &.blue {
        animation: fillBlue 1.5s 1.4s linear forwards,
          dash 5s 1.4s linear forwards;
      }

      &.white {
        animation: fillWhite 1.5s 1.4s linear forwards,
          dash 5s 1.4s linear forwards;
      }
    }
  }
}

@keyframes fadeInLogo {
  from {
    position: absolute;
  }
  to {
    position: relative;
  }
}

@keyframes moveLogo {
  from {
    transform: translateX(30%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fillWhite {
  from {
    opacity: 0;
    fill: $dark;
  }
  to {
    opacity: 1;
    fill: #fff;
  }
}

@keyframes fillBlue {
  from {
    opacity: 0;
    fill: #fff;
  }
  to {
    opacity: 1;
    fill: #2cbacb;
  }
}
